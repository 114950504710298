/* eslint-disable @typescript-eslint/no-unused-vars */

import { AuthUIActions } from '@brightlayer-ui/react-auth-workflow';
import { AxiosError, HttpStatusCode } from 'axios';
import i18n from 'i18next';
import { AppDispatch, store } from '../app/store';
import { setCredentialsAndUser, setRememberMe, UserSelectors } from '../features/userSlice';
import { UserService } from '../services/user/user.service';

type AuthUIActionsBuilder = (dispatch: AppDispatch) => AuthUIActions;
/**
 * Example implementation of [[AuthUIActions]] to start with during development.
 *
 * Authentication Actions to be performed based on the user's UI actions. The application will create
 * appropriate actions (often api calls, local network storage, credential updates, etc) and update
 * the global security state based on the actionable needs of the user.
 */
export const ProjectAuthUIActions: AuthUIActionsBuilder = (dispatch): AuthUIActions => ({
    // export const ProjectAuthUIActions = (): AuthUIActions => ({
    /**
     * Initialize the application security state. This will involve reading any local storage,
     * validating existing credentials (token expiration, for example). At the end of validation,
     * the [[SecurityContextActions]] should be called with either:
     * [[onUserAuthenticated]] (which will present the application), or
     * [[onUserNotAuthenticated]] (which will present the Auth UI).
     *
     * Note: Until this method returns, the applications Splash screen will be presented.
     *
     * @returns Should always resolve. Never throw.
     */
    initiateSecurity: (): Promise<void> => Promise.resolve(),
    /**
     * The user wants to log into the application. Perform a login with the user's credentials.
     * The application should provide the user's email and password to the authentication server.
     *
     * In the case of valid credentials, the applications code should store the returned data
     * (such as token, user information, etc.). Then the [[onUserAuthenticated]] function should
     * be called on the [[SecurityContextActions]] object.
     *
     * For example:
     * ```
     * LocalStorage.saveAuthCredentials(email, email);
     * LocalStorage.saveRememberMeData(email, rememberMe);
     *
     * securityHelper.onUserAuthenticated({ email: email, userId: email, rememberMe: rememberMe });
     * ```
     *
     * In the case of invalid credentials, an error should be thrown.
     *
     * @param email Email address the user entered into the UI.
     * @param password Password the user entered into the UI.
     * @param rememberMe Indicates whether the user's email should be remembered on success.
     *
     * @returns Resolve if code is credentials are valid, otherwise reject.
     */
    logIn: async (email: string, password: string, rememberMe: boolean): Promise<void> => {
        const credentials = await UserService.login(email, password);
        const user = await UserService.getUser(credentials.id, credentials.authToken.accessToken);

        dispatch(setCredentialsAndUser({ credentials, user }));

        dispatch(setRememberMe(rememberMe ? { email } : undefined));
    },
    /**
     * The user has forgotten their password and wants help.
     * The application generally should call an API which will then send a password reset
     * link to the user's email.
     *
     * @param email Email address the user entered into the UI.
     *
     * @returns Resolve if email sending was successful, otherwise reject.
     */
    forgotPassword: async (email: string): Promise<void> => {
        await UserService.forgotPassword(email);
    },
    /**
     * The user has tapped on an email with a password reset link, which they received after
     * requesting help for forgetting their password.
     * The application should take the password reset code and then verify that it is still
     * valid.
     *
     * @param code Password reset code from a reset password link.
     * @param email Email if it was passed from the reset link
     *
     * @returns Resolve if code is valid, otherwise reject.
     */
    verifyResetCode: (code: string, email?: string): Promise<void> => Promise.resolve(),
    /**
     * A user who has previously used "forgotPassword" now has a valid password reset code
     * and has entered a new password.
     * The application should take the user's password reset code and the newly entered
     * password and then reset the user's password.
     *
     * Note: Upon success, the user will be taken to the Login screen.
     *
     * @param code Password reset code from a link
     * @param password New Password the user entered into the UI
     * @param email Email if it was passed from the reset link
     *
     * @returns Resolve if successful, otherwise reject with an error message.
     */
    setPassword: (code: string, password: string, email?: string): Promise<void> => Promise.resolve(),
    /**
     * An authenticated user wants to change their password.
     * The application should try to change the user's password. Upon completion,
     * the user will be logged out of the application. Upon cancellation, the user
     * will be taken back to the application's home screen.
     *
     * @param oldPassword The user's current password as entered into the UI.
     * @param newPassword The user's new password as entered into the UI.
     *
     * @returns Resolve if successful, otherwise reject with an error message.
     */
    changePassword: async (oldPassword: string, newPassword: string): Promise<void> => {
        const currentUser = UserSelectors.userDetails(store.getState());

        if (!currentUser) {
            throw new Error(i18n.t('CHANGE_PASSWORD.MISSING_USER'));
        }

        try {
            await UserService.changePassword({ currentPassword: oldPassword, newPassword, id: currentUser?.id });
        } catch (e) {
            if (e instanceof AxiosError && e.response?.status === HttpStatusCode.Forbidden) {
                throw new Error(i18n.t('bluiAuth:CHANGE_PASSWORD.ERROR_MESSAGE'));
            }
            throw new Error(i18n.t('bluiAuth:CHANGE_PASSWORD.PROBLEM_OCCURRED'));
        }
    },
});

import * as Colors from '@brightlayer-ui/colors';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertType, useAlert } from '../lib/AlertContext';

const styles = {
    dialogBlock: {
        backgroundColor: Colors.blue[700],
        color: Colors.white[50],
    },
    inputBox: {
        margin: 2,
        fontWeight: 'bold',
    },
    inputLabel: {
        min: 10,
    },
    infoOuterBox: {
        alignContent: 'center',
        textAlign: 'center',
        marginBottom: 2,
    },
    contentContainer: {
        marginBottom: '20px',
    },
    button: {
        margin: '12px',
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    leftActions: {
        display: 'flex',
        alignItems: 'center',
    },
    rightActions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
    },
};

export const AlertModal: React.FC = () => {
    const { clearAlert, alert } = useAlert();
    const { t } = useTranslation();

    const [loadingState, setLoadingState] = useState(false);
    const [error, setError] = useState(false);

    const onClickRightButton = async (a: Alert): Promise<void> => {
        setLoadingState(true);

        try {
            if (a.type === AlertType.CONFIRMATION) {
                await a.onConfirm();
            }
            if (a.type === AlertType.INFORMATIONAL) {
                await a.onDismiss?.();
            }
            clearAlert();
        } catch (e) {
            setError(true);
        } finally {
            setLoadingState(false);
        }
    };

    return alert ? (
        <>
            <Dialog open={true} maxWidth="sm" data-testid={alert.testId ?? 'alert-modal'}>
                <DialogTitle>{error ? alert.errorTitle : alert.title}</DialogTitle>
                <DialogContent sx={{ ...styles.contentContainer, ...(error ? { color: 'red' } : {}) }}>
                    {error ? alert.errorDescription : alert.description}
                </DialogContent>
                <Divider />
                <DialogActions sx={styles.actionsContainer}>
                    {alert.type === AlertType.CONFIRMATION && (
                        <Box sx={styles.leftActions}>
                            <Button
                                data-testid="alert-modal-cancel-button"
                                variant="outlined"
                                disabled={loadingState}
                                onClick={(): void => {
                                    void alert.onCancel?.();
                                    clearAlert();
                                }}
                            >
                                {alert.cancelText}
                            </Button>
                        </Box>
                    )}
                    <Box sx={styles.rightActions}>
                        <Button
                            data-testid="alert-modal-ok-button"
                            variant="contained"
                            disabled={loadingState || error}
                            onClick={(): void => {
                                void onClickRightButton(alert);
                            }}
                        >
                            {loadingState ? (
                                <CircularProgress data-testid="alert-modal-loading" size="1.5rem" color="inherit" />
                            ) : alert.type === AlertType.CONFIRMATION ? (
                                alert.confirmText
                            ) : (
                                alert.clearAlertText ?? t('COMMON.OK')
                            )}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    ) : null;
};

import * as Colors from '@brightlayer-ui/colors';
import { Box, Button, Link, Theme, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CopyButton } from '../../components/CopyButton';
import { FilledTextField } from '../../components/FilledTextField';
import { Footer } from '../../components/Footer';
import { RegenerateButton } from '../../components/RegenerateButton';
import { Spinner } from '../../components/Spinner';
import { getUsersInOrganization, OrganizationSelectors } from '../../features/organizationSlice';
import { UserSelectors } from '../../features/userSlice';
import { Organization } from '../../services/organization/types';
import { UserRole } from '../../services/user/types';
import { formatDate } from '../../utils';
import { ManageInstallerAppRegistration } from './ManageInstallerAppRegistration';
import { UsersList } from './UsersList';

const styles = {
    container: {
        marginLeft: '25px',
        marginRight: '25px',
        marginTop: '25px',
        flex: '1 0 auto',
        paddingBottom: '20px',
        width: '40%',
        '@media (max-width: 900px)': {
            width: '100%',
            marginLeft: '10px',
            marginRight: '10px',
        },
    },
    sectionHeader: {
        paddingBottom: 2,
    },
    header: {
        marginBottom: '1rem',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginRight: '25px',
    },
    textBoxes: {
        display: 'flex',
        flexDirection: 'column',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    pointOfContactContainer: {
        marginBottom: '10px',
    },
    pointOfContactText: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        color: Colors.black[500],
    },
    contactInfoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    contactInfoText: {
        color: Colors.black[400],
    },
    emailText: {
        color: Colors.blue[400],
    },
    credentialsContainer: {
        marginTop: '16px',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh', // Ensures the container takes the full height of the screen
    },
    buttonContainer: (theme: Theme): object => ({
        paddingLeft: theme.spacing(1),
    }),
    button: {
        marginTop: '35px',
        marginBottom: '25px',
        textTransform: 'uppercase',
    },
};

type OrganizationDetailsProps = {
    organization: Organization;
};

export const OrganizationDetails: React.FC<OrganizationDetailsProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { organization } = props;
    const { name, email, phoneNumber } = organization.pointOfContact;

    const currentUserId = useAppSelector((state) => UserSelectors.userDetails(state))?.id;
    const userRole = useAppSelector((state) =>
        OrganizationSelectors.getUserRoleInOrganization(state, organization.id, currentUserId ?? '')
    );
    const usersInOrg = useAppSelector((state) =>
        OrganizationSelectors.getUsersInOrganization(state, organization.id ?? '')
    );
    const mappedUsersInOrg = Object.keys(usersInOrg).map((id) => ({ id, ...usersInOrg[id] }));
    const isUserOwner = userRole && currentUserId && userRole === UserRole.OWNER;

    const isExpired = (secretExpiry: string): boolean => secretExpiry < DateTime.now().toISO();

    useEffect(() => {
        const fetchUsersInOrganization = async (): Promise<void> => {
            await dispatch(getUsersInOrganization(organization.id));
        };

        void fetchUsersInOrganization();
    }, [dispatch, organization.id]);

    return (
        <Suspense fallback={<Spinner />}>
            <Box sx={styles.main}>
                <Box sx={styles.container}>
                    <Box sx={styles.header}>
                        <Typography data-testid="item-details-header-title" variant="h4">
                            {organization.name}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h5" sx={styles.sectionHeader}>
                            {t('ORGANIZATION_DETAILS_PAGE.DESCRIPTION_HEADER')}
                        </Typography>
                        <Typography>{organization.description}</Typography>
                    </Box>
                    <Box sx={styles.credentialsContainer}>
                        <Typography variant="h5" sx={styles.sectionHeader}>
                            {t('ORGANIZATION_DETAILS_PAGE.ORGANIZATION_CREDENTIALS')}
                        </Typography>
                        <Box sx={styles.textBoxes}>
                            <FilledTextField
                                label={t('ORGANIZATION_DETAILS_PAGE.CLIENT_ID')}
                                defaultValue={organization.serviceAccount.clientId}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <CopyButton content={organization.serviceAccount.clientId} />,
                                }}
                            />
                            <Box sx={styles.details}>
                                {organization.serviceAccount.clientSecrets.map((secret) => (
                                    <FilledTextField
                                        key={secret.name}
                                        label={`${secret.name} ${t('ORGANIZATION_DETAILS_PAGE.EXPIRY')}`}
                                        value={formatDate(DateTime.fromISO(secret.expiration))}
                                        error={isExpired(secret.expiration)}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: isUserOwner && (
                                                <RegenerateButton
                                                    secretName={secret.name}
                                                    organizationName={organization.name}
                                                    organizationId={organization.id}
                                                />
                                            ),
                                        }}
                                        helperText={
                                            isExpired(secret.expiration)
                                                ? `${secret.name} ${t('ORGANIZATION_DETAILS_PAGE.IS_EXPIRED')}`
                                                : undefined
                                        }
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    <ManageInstallerAppRegistration
                        organizationName={organization.name}
                        organizationId={organization.id}
                        isUserOwner={!!isUserOwner}
                    />
                    <UsersList users={mappedUsersInOrg} organization={organization} isUserOwner={!!isUserOwner} />
                    {isUserOwner && (
                        <Box sx={styles.buttonContainer}>
                            <Button
                                sx={styles.button}
                                variant="outlined"
                                color="primary"
                                data-testid="edit-organization-button"
                                onClick={(): void => {
                                    navigate(`/organizations/${organization.id}/edit`);
                                }}
                            >
                                {t('ORGANIZATION_DETAILS_PAGE.EDIT_BUTTON')}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Footer>
                    <Box sx={styles.pointOfContactContainer}>
                        <Typography sx={styles.pointOfContactText}>
                            {t('ORGANIZATION_DETAILS_PAGE.POINT_OF_CONTACT')}
                        </Typography>
                    </Box>
                    <Box sx={styles.contactInfoContainer}>
                        <Typography sx={styles.contactInfoText}>{name}</Typography>
                        <Typography sx={styles.contactInfoText}>•</Typography>
                        <Link href={`mailto:${email}`} underline="none" sx={styles.emailText}>
                            {email}
                        </Link>
                        {phoneNumber && (
                            <>
                                <Typography sx={styles.contactInfoText}>•</Typography>
                                <Typography sx={styles.contactInfoText}>{phoneNumber}</Typography>
                            </>
                        )}
                    </Box>
                </Footer>
            </Box>
        </Suspense>
    );
};

import * as Colors from '@brightlayer-ui/colors';
import { Box, Button, CircularProgress, Theme, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../app/hooks';
import { FilledTextField } from '../../components/FilledTextField';
import { updateOrganization } from '../../features/organizationSlice';
import { useAlert } from '../../lib/AlertContext';
import { Organization } from '../../services/organization/types';
import { OrganizationFormValidations } from '../validations';

const styles = {
    sectionHeader: {
        paddingBottom: 2,
    },
    title: {
        color: Colors.blue['500'],
    },
    content: {
        color: Colors.black['500'],
    },
    textAndTitle: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
    },
    organizationNameForm: {
        display: 'flex',
        flexDirection: 'column',
    },
    textField: {
        maxWidth: '400px',
        color: Colors.white[200],
        width: '100%',
    },
    formHelperText: {
        marginLeft: '0px',
    },
    divider: {
        marginBottom: '25px',
    },
    buttonContainer: (theme: Theme): object => ({
        paddingLeft: theme.spacing(1),
    }),
    submitButton: {
        maxWidth: '90px',
        marginTop: '20px',
        textTransform: 'uppercase',
    },
    circularProgress: {
        color: 'white',
    },
    container: {
        paddingLeft: '25px',
        paddingTop: '25px',
    },
};

type EditOrganizationFormProps = {
    organization: Organization;
};

export const EditOrganizationForm: React.FC<EditOrganizationFormProps> = (props) => {
    const { organization } = props;
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { showInfoAlertToUser } = useAlert();

    const [organizationName, setOrganizationName] = useState(organization.name);
    const [organizationDescription, setOrganizationDescription] = useState(organization.description);
    const [pointOfContactName, setPointOfContactName] = useState(organization.pointOfContact.name);
    const [pointOfContactEmail, setPointOfContactEmail] = useState(organization.pointOfContact.email);
    const [pointOfContactPhoneNumber, setPointOfContactPhoneNumber] = useState(organization.pointOfContact.phoneNumber);

    const [organizationNameError, setOrganizationNameError] = useState(false);
    const [organizationDescriptionError, setOrganizationDescriptionError] = useState(false);
    const [pointOfContactNameError, setPointOfContactNameError] = useState(false);
    const [pointOfContactEmailError, setPointOfContactEmailError] = useState(false);
    const [pointOfContactPhoneNumberError, setPointOfContactPhoneNumberError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const renderFailedToUpdateModal = (): void => {
        showInfoAlertToUser({
            title: t('EDIT_ORGANIZATION_PAGE.FAILED_TO_EDIT_TITLE'),
            description: t('EDIT_ORGANIZATION_PAGE.FAILED_TO_EDIT_MESSAGE'),
            clearAlertText: t('COMMON.TRY_AGAIN'),
        });
    };

    const onSubmit = async (): Promise<void> => {
        const org = {
            id: organization.id,
            name: organizationName,
            description: organizationDescription,
            pointOfContact: {
                name: pointOfContactName,
                email: pointOfContactEmail,
                phoneNumber: pointOfContactPhoneNumber,
            },
        };

        setIsLoading(true);
        const result = await dispatch(updateOrganization(org));
        setIsLoading(false);

        if (updateOrganization.fulfilled.match(result)) {
            navigate(`/organizations/${organization.id}`);
        } else if (updateOrganization.rejected.match(result)) {
            renderFailedToUpdateModal();
        }
    };

    const unchanged = useMemo(
        () =>
            organizationName === organization.name &&
            organizationDescription === organization.description &&
            pointOfContactName === organization.pointOfContact.name &&
            pointOfContactEmail === organization.pointOfContact.email &&
            pointOfContactPhoneNumber === organization.pointOfContact.phoneNumber,
        [organizationName, organizationDescription, pointOfContactName, pointOfContactEmail, pointOfContactPhoneNumber]
    );

    const missingRequiredFields = useMemo(
        () =>
            organizationName === '' ||
            organizationDescription === '' ||
            pointOfContactName === '' ||
            pointOfContactEmail === '',
        [organizationName, organizationDescription, pointOfContactName, pointOfContactEmail]
    );

    const invalidForm = useMemo(
        () =>
            organizationNameError ||
            organizationDescriptionError ||
            pointOfContactNameError ||
            pointOfContactEmailError ||
            pointOfContactPhoneNumberError,
        [
            organizationNameError,
            organizationDescriptionError,
            pointOfContactNameError,
            pointOfContactEmailError,
            pointOfContactPhoneNumberError,
        ]
    );

    return (
        <Box sx={styles.container}>
            <Box sx={styles.organizationNameForm}>
                <Box sx={styles.sectionHeader}>
                    <Typography variant="h6">{t('EDIT_ORGANIZATION_PAGE.TITLE')}</Typography>
                </Box>
                <Box>
                    <FilledTextField
                        label={t('EDIT_ORGANIZATION_PAGE.ORG_NAME_LABEL')}
                        data-testid="organization-name-text-box"
                        sx={styles.textField}
                        FormHelperTextProps={{ sx: styles.formHelperText }}
                        error={organizationNameError}
                        helperText={organizationNameError ? t('EDIT_ORGANIZATION_PAGE.ORG_NAME_ERROR') : undefined}
                        value={organizationName}
                        onChange={(event): void => {
                            setOrganizationNameError(false);
                            setOrganizationName(event.target.value);
                        }}
                        onBlur={(): void => {
                            setOrganizationNameError(
                                !OrganizationFormValidations.organizationNameValidator(organizationName)
                            );
                        }}
                    />

                    <FilledTextField
                        label={t('EDIT_ORGANIZATION_PAGE.ORG_DESC_LABEL')}
                        data-testid="organization-description-text-box"
                        sx={styles.textField}
                        FormHelperTextProps={{ sx: styles.formHelperText }}
                        error={organizationDescriptionError}
                        helperText={
                            organizationDescriptionError ? t('EDIT_ORGANIZATION_PAGE.ORG_DESCRIPTION_ERROR') : undefined
                        }
                        value={organizationDescription}
                        onChange={(event): void => {
                            setOrganizationDescriptionError(false);
                            setOrganizationDescription(event.target.value);
                        }}
                        onBlur={(): void => {
                            if (organizationDescription !== undefined) {
                                setOrganizationDescriptionError(
                                    !OrganizationFormValidations.organizationDescriptionValidator(
                                        organizationDescription
                                    )
                                );
                            }
                        }}
                    />
                </Box>
                <Box sx={styles.sectionHeader}>
                    <Typography variant="h6">{t('EDIT_ORGANIZATION_PAGE.POC_DETAILS')}</Typography>
                </Box>
                <Box>
                    <FilledTextField
                        label={t('EDIT_ORGANIZATION_PAGE.POC_NAME_LABEL')}
                        data-testid="point-of-contact-name-text-box"
                        sx={styles.textField}
                        FormHelperTextProps={{ sx: styles.formHelperText }}
                        error={pointOfContactNameError}
                        helperText={pointOfContactNameError ? t('EDIT_ORGANIZATION_PAGE.POC_NAME_ERROR') : undefined}
                        value={pointOfContactName}
                        onChange={(event): void => {
                            setPointOfContactNameError(false);
                            setPointOfContactName(event.target.value);
                        }}
                        onBlur={(): void => {
                            if (pointOfContactName !== undefined) {
                                setPointOfContactNameError(
                                    !OrganizationFormValidations.pointOfContactNameValidator(pointOfContactName)
                                );
                            }
                        }}
                    />
                    <FilledTextField
                        label={t('EDIT_ORGANIZATION_PAGE.POC_EMAIL_LABEL')}
                        data-testid="point-of-contact-email-text-box"
                        sx={styles.textField}
                        FormHelperTextProps={{ sx: styles.formHelperText }}
                        error={pointOfContactEmailError}
                        helperText={pointOfContactEmailError ? t('EDIT_ORGANIZATION_PAGE.POC_EMAIL_ERROR') : undefined}
                        value={pointOfContactEmail}
                        onChange={(event): void => {
                            setPointOfContactEmailError(false);
                            setPointOfContactEmail(event.target.value);
                        }}
                        onBlur={(): void => {
                            if (pointOfContactEmail !== undefined) {
                                setPointOfContactEmailError(
                                    !OrganizationFormValidations.pointOfContactEmailValidator(pointOfContactEmail)
                                );
                            }
                        }}
                    />
                    <FilledTextField
                        label={t('EDIT_ORGANIZATION_PAGE.POC_PHONE_NUMBER_LABEL')}
                        data-testid="point-of-contact-phone-number-text-box"
                        sx={styles.textField}
                        FormHelperTextProps={{ sx: styles.formHelperText }}
                        error={pointOfContactPhoneNumberError}
                        helperText={
                            pointOfContactPhoneNumberError
                                ? t('EDIT_ORGANIZATION_PAGE.POC_PHONE_NUMBER_ERROR')
                                : undefined
                        }
                        value={pointOfContactPhoneNumber}
                        onChange={(event): void => {
                            setPointOfContactPhoneNumberError(false);
                            setPointOfContactPhoneNumber(event.target.value === '' ? undefined : event.target.value);
                        }}
                        onBlur={(): void => {
                            if (pointOfContactPhoneNumber) {
                                setPointOfContactPhoneNumberError(
                                    !OrganizationFormValidations.pointOfContactPhoneNumberValidator(
                                        pointOfContactPhoneNumber
                                    )
                                );
                            }
                        }}
                    />
                </Box>
                <Button
                    sx={styles.submitButton}
                    id="submit-button"
                    variant="contained"
                    color="primary"
                    onClick={(): void => void onSubmit()}
                    disabled={unchanged || invalidForm || missingRequiredFields}
                >
                    {isLoading ? (
                        <Box data-testid="update-org-spinner">
                            <CircularProgress sx={styles.circularProgress} size={20} variant={'indeterminate'} />
                        </Box>
                    ) : (
                        t('COMMON.SUBMIT')
                    )}
                </Button>
            </Box>
        </Box>
    );
};

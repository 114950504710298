import { InfoOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, Theme, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import { ChangePasswordButton } from '../../components/ChangePasswordButton';
import { FilledTextField } from '../../components/FilledTextField';
import { updateUser } from '../../features/userSlice';
import { useAlert } from '../../lib/AlertContext';
import { User } from '../../services/user/types';
import { AccountInfoValidations } from './validations';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
    emailContainer: {
        display: 'flex',
        alignItems: 'baseline',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'baseline',
    },
    button: {
        marginBottom: '25px',
        textTransform: 'uppercase',
    },
    buttonSave: { marginRight: '15px', minWidth: '64px' },
    circularProgress: {
        color: 'white',
    },
    changePasswordButtonContainer: (theme: Theme): object => ({
        paddingLeft: theme.spacing(1),
    }),
    divider: {
        marginBottom: '25px',
    },
};

type AccountEditProps = {
    user: User;
    exitEditMode: () => void;
};

export const AccountEdit: React.FC<AccountEditProps> = (props) => {
    const { user, exitEditMode } = props;

    const dispatch = useAppDispatch();
    const { showConfirmationToUser } = useAlert();
    const { t } = useTranslation();

    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
    const [company, setCompany] = useState(user.company);
    const [isSaving, setIsSaving] = useState(false);

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);

    const renderFailedToUpdateModal = (): void => {
        showConfirmationToUser({
            title: t('ACCOUNT_INFO_PAGE.FAILED_TO_UPDATE_TITLE'),
            description: t('ACCOUNT_INFO_PAGE.FAILED_TO_UPDATE_MESSAGE'),
            cancelText: t('COMMON.CANCEL'),
            confirmText: t('COMMON.TRY_AGAIN'),
            onConfirm: () => {},
            onCancel: exitEditMode,
        });
    };

    const onSave = async (): Promise<void> => {
        const args = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber,
            company,
        };

        setIsSaving(true);

        const result = await dispatch(updateUser(args));

        if (updateUser.fulfilled.match(result)) {
            setIsSaving(false);
            exitEditMode();
        } else if (updateUser.rejected.match(result)) {
            setIsSaving(false);
            renderFailedToUpdateModal();
        }
    };

    const unchanged = useMemo(
        () =>
            firstName === user.firstName &&
            lastName === user.lastName &&
            phoneNumber === user.phoneNumber &&
            company === user.company,
        [firstName, lastName, phoneNumber, company]
    );

    const invalidForm = useMemo(
        () => firstNameError || lastNameError || phoneNumberError || companyError,
        [firstNameError, lastNameError, phoneNumberError, companyError]
    );

    return (
        <Box sx={styles.container}>
            <FilledTextField
                data-testid="first-name-edit"
                label={t('ACCOUNT_INFO_PAGE.FIRST_NAME_LABEL')}
                value={firstName}
                content={firstName}
                onChange={(e): void => {
                    setFirstNameError(false);
                    setFirstName(e.target.value);
                }}
                error={firstNameError}
                helperText={firstNameError ? t('VALIDATION_ERRORS.ACCOUNT_DETAILS.FIRST_NAME_ERROR') : undefined}
                onBlur={(): void => {
                    setFirstNameError(!AccountInfoValidations.firstNameValidator(firstName));
                }}
            />
            <FilledTextField
                data-testid="last-name-edit"
                label={t('ACCOUNT_INFO_PAGE.LAST_NAME_LABEL')}
                value={lastName}
                content={lastName}
                onChange={(e): void => {
                    setLastNameError(false);
                    setLastName(e.target.value);
                }}
                error={lastNameError}
                helperText={lastNameError ? t('VALIDATION_ERRORS.ACCOUNT_DETAILS.LAST_NAME_ERROR') : undefined}
                onBlur={(): void => {
                    setLastNameError(!AccountInfoValidations.lastNameValidator(lastName));
                }}
            />
            <Box sx={styles.emailContainer}>
                <FilledTextField
                    data-testid="email-edit"
                    label={t('ACCOUNT_INFO_PAGE.EMAIL_LABEL')}
                    value={user.email}
                    content={user.email}
                    disabled
                    InputProps={{ readOnly: true }}
                />
                <Tooltip title={t('ACCOUNT_INFO_PAGE.EMAIL_CANNOT_BE_CHANGED')}>
                    <InfoOutlined aria-label="email-cannot-be-changed" />
                </Tooltip>
            </Box>
            <FilledTextField
                data-testid="phone-number-edit"
                label={t('ACCOUNT_INFO_PAGE.PHONE_NUMBER_LABEL')}
                value={phoneNumber || ''}
                content={phoneNumber}
                onChange={(e): void => {
                    setPhoneNumberError(false);
                    setPhoneNumber(e.target.value === '' ? undefined : e.target.value);
                }}
                error={phoneNumberError}
                helperText={phoneNumberError ? t('VALIDATION_ERRORS.ACCOUNT_DETAILS.PHONE_NUMBER_ERROR') : undefined}
                onBlur={(): void => {
                    if (phoneNumber) {
                        setPhoneNumberError(!AccountInfoValidations.phoneNumberValidator(phoneNumber));
                    }
                }}
            />
            <FilledTextField
                data-testid="company-edit"
                label={t('ACCOUNT_INFO_PAGE.COMPANY_NAME_LABEL')}
                value={company || ''}
                content={company}
                onChange={(e): void => {
                    setCompanyError(false);
                    setCompany(e.target.value === '' ? undefined : e.target.value);
                }}
                error={companyError}
                helperText={companyError ? t('VALIDATION_ERRORS.ACCOUNT_DETAILS.COMPANY_NAME_ERROR') : undefined}
                onBlur={(): void => {
                    if (company) {
                        setCompanyError(!AccountInfoValidations.companyNameValidator(company));
                    }
                }}
            />
            <Box sx={styles.buttonContainer}>
                <Button
                    data-testid="save-account-info-button"
                    variant="contained"
                    color="primary"
                    onClick={(): void => {
                        void onSave();
                    }}
                    sx={{ ...styles.button, ...styles.buttonSave }}
                    disableElevation
                    disabled={unchanged || invalidForm || isSaving}
                >
                    {isSaving ? (
                        <CircularProgress sx={styles.circularProgress} size={20} variant={'indeterminate'} />
                    ) : (
                        t('COMMON.SAVE')
                    )}
                </Button>
                <Button
                    data-testid="cancel-account-info-button"
                    variant="outlined"
                    color="primary"
                    onClick={exitEditMode}
                    sx={styles.button}
                    disabled={isSaving}
                >
                    {t('COMMON.CANCEL')}
                </Button>
            </Box>
            <Divider sx={styles.divider} />
            <Box sx={styles.changePasswordButtonContainer}>
                <ChangePasswordButton disabled={isSaving} />
            </Box>
        </Box>
    );
};

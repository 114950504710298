import React, { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

type InformationalAlert = {
    title: string;
    description: string | JSX.Element;
    testId?: string;
    clearAlertText?: string;
    onDismiss?: () => void | Promise<void>;
    errorTitle?: string;
    errorDescription?: string;
};

type ConfirmationAlert = {
    title: string;
    description: string | JSX.Element;
    testId?: string;
    warning?: string;
    confirmText: string;
    cancelText: string;
    onConfirm: () => void | Promise<void>;
    onCancel?: () => void;
    errorTitle?: string;
    errorDescription?: string;
};

export enum AlertType {
    INFORMATIONAL = 'information',
    CONFIRMATION = 'confirmation',
}

export type Alert =
    | ({ type: AlertType.INFORMATIONAL } & InformationalAlert)
    | ({ type: AlertType.CONFIRMATION } & ConfirmationAlert);

type AlertState = { alert: Alert | undefined };

type DisplayAlertFunctions = {
    showInfoAlertToUser: (alertInfo: InformationalAlert) => void;
    showConfirmationToUser: (alertInfo: ConfirmationAlert) => void;
    clearAlert: () => void;
};

export type AlertContextType = AlertState & DisplayAlertFunctions;

export const AlertContext = createContext<AlertContextType>({
    alert: undefined,
    showInfoAlertToUser: () => {},
    showConfirmationToUser: () => {},
    clearAlert: () => {},
});

export const AlertProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [alertState, setAlertState] = useState<AlertState>({
        alert: undefined,
    });

    const showInfoAlertToUser = useCallback(
        (alertInfo: InformationalAlert) => {
            setAlertState({ alert: { ...alertInfo, type: AlertType.INFORMATIONAL } });
        },
        [setAlertState]
    );

    const showConfirmationToUser = useCallback(
        (alertInfo: ConfirmationAlert) => {
            setAlertState({ alert: { ...alertInfo, type: AlertType.CONFIRMATION } });
        },
        [setAlertState]
    );

    const clearAlert = useCallback(() => {
        setAlertState({ alert: undefined });
    }, [setAlertState]);

    return (
        <AlertContext.Provider
            value={{
                ...alertState,
                showInfoAlertToUser,
                showConfirmationToUser,
                clearAlert,
            }}
        >
            {children}
        </AlertContext.Provider>
    );
};

export const useAlert = (): typeof context => {
    const context = useContext(AlertContext);

    if (context === null) {
        throw new Error('useAlert must be used within an alert context provider.');
    }
    return context;
};

import * as Colors from '@brightlayer-ui/colors';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { Group } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import {
    assignOrganizationRoleToUser,
    getUsersInOrganization,
    removeOrganizationUser,
} from '../../features/organizationSlice';
import { useAlert } from '../../lib/AlertContext';
import { OrganizationService } from '../../services/organization/organization.service';
import { GetUsersInOrganization, Organization } from '../../services/organization/types';
import { UserRole } from '../../services/user/types';
import { AddUserModal } from './AddUserModal';
import { UsersListItem } from './UsersListItem';

export const styles = {
    bar: {
        minHeight: '72px',
        paddingTop: 0,
        paddingBottom: 0,
        margin: 0,
    },
    usersBox: { zIndex: 2 },
    rightComponent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '30%',
        minHeight: 'inherit',
    },
    groupIcon: { marginLeft: '10px', color: Colors.gray[300] },
    userCount: { marginLeft: '25px' },
    button: { textTransform: 'uppercase' },
};

type UsersListProps = {
    users: GetUsersInOrganization[];
    organization: Organization;
    isUserOwner: boolean;
};

export const UsersList: React.FC<UsersListProps> = (props) => {
    const { users, isUserOwner, organization } = props;
    const { t } = useTranslation();
    const { showConfirmationToUser } = useAlert();
    const dispatch = useAppDispatch();

    const sorted = _.sortBy(users, ['firstName', 'lastName']);

    const [selectedUser, setSelectedUser] = useState<GetUsersInOrganization | null>(null);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorAddingUser, setErrorAddingUser] = useState(false);

    const handleAddUserToOrg = async (userEmail: string, role: UserRole): Promise<void> => {
        try {
            setLoading(true);

            const body = {
                organizationId: organization.id,
                email: userEmail,
                role,
            };
            await OrganizationService.assignOrganizationRoleToUser(body);
            /**
             * LC - 10/23/2024
             * Another dispatch is triggered here to re-render the Organization Details page without refreshing.
             * Originally, a re-render would've been triggered because of the reducer.
             * However, when an email and role is provided, but not a userId, there is no way to store a new user
             * because users in the Organization state are stored as a record with the userId as the key.
             * Without the key, we cannot accurately store the new user and update the state, so it's better to dispatch this action again
             */
            await dispatch(getUsersInOrganization(organization.id));

            setLoading(false);
            setShowAddUserModal(false);
        } catch (e) {
            setLoading(false);
            setErrorAddingUser(true);
        }
    };

    const handleChangeUserRole = async (): Promise<void> => {
        if (selectedUser) {
            const result = await dispatch(
                assignOrganizationRoleToUser({
                    organizationId: organization.id,
                    userId: selectedUser.id,
                    role: selectedUser.role === UserRole.DEVELOPER ? UserRole.OWNER : UserRole.DEVELOPER,
                })
            );

            if (assignOrganizationRoleToUser.fulfilled.match(result)) {
                setSelectedUser(null);
            } else if (assignOrganizationRoleToUser.rejected.match(result)) {
                throw new Error('Failed to remove this user');
            }
        }
    };

    const renderChangeUserRoleModal = (): void => {
        showConfirmationToUser({
            title: t('ORGANIZATION_DETAILS_PAGE.UPDATE_ROLE_MODAL_TITLE', {
                newRole: selectedUser?.role === UserRole.DEVELOPER ? 'Owner' : 'Developer',
            }),
            description: (
                <>
                    {selectedUser?.role === UserRole.DEVELOPER
                        ? t('ORGANIZATION_DETAILS_PAGE.UPDATE_ROLE_MODAL_OWNER_BODY', {
                              firstName: selectedUser?.firstName,
                              lastName: selectedUser?.lastName,
                          })
                        : t('ORGANIZATION_DETAILS_PAGE.UPDATE_ROLE_MODAL_DEV_BODY', {
                              firstName: selectedUser?.firstName,
                              lastName: selectedUser?.lastName,
                          })}
                    <br />
                    <br />
                    {t('ORGANIZATION_DETAILS_PAGE.UPDATE_ROLE_MODAL_QUESTION')}
                </>
            ),
            testId: 'change-user-role-modal',
            confirmText: t('COMMON.UPDATE'),
            cancelText: t('COMMON.CANCEL'),
            onConfirm: handleChangeUserRole,
            errorTitle: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_ADD_USER_ROLE_TO_ORG_TITLE'),
            errorDescription: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_ADD_USER_ROLE_TO_ORG_BODY', {
                role: selectedUser?.role === UserRole.DEVELOPER ? t('COMMON.OWNER') : t('COMMON.DEVELOPER'),
            }),
        });
    };

    const handleRemoveUser = async (): Promise<void> => {
        if (selectedUser) {
            const result = await dispatch(
                removeOrganizationUser({
                    organizationId: organization.id,
                    userId: selectedUser.id,
                })
            );

            if (removeOrganizationUser.fulfilled.match(result)) {
                setSelectedUser(null);
            } else if (removeOrganizationUser.rejected.match(result)) {
                throw new Error('Failed to remove this user');
            }
        }
    };

    const renderRemoveUserModal = (): void => {
        showConfirmationToUser({
            title: t('ORGANIZATION_DETAILS_PAGE.REMOVE_USER_MENU_ITEM'),
            description: (
                <>
                    {t('ORGANIZATION_DETAILS_PAGE.REMOVE_USER_MODAL_BODY', {
                        firstName: selectedUser?.firstName,
                        lastName: selectedUser?.lastName,
                    })}
                </>
            ),
            confirmText: t('COMMON.REMOVE'),
            cancelText: t('COMMON.CANCEL'),
            testId: 'remove-user-modal',
            onConfirm: handleRemoveUser,
            errorTitle: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_REMOVE_USER_TO_ORG_TITLE'),
            errorDescription: t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_REMOVE_USER_TO_ORG_BODY'),
        });
    };

    const getMenuItems = (role: UserRole): Array<{ testid: string; text: string; onClick: () => void }> => [
        {
            testid: role === UserRole.DEVELOPER ? 'make-owner' : 'make-developer',
            text:
                role === UserRole.DEVELOPER
                    ? t('ORGANIZATION_DETAILS_PAGE.MAKE_USER_OWNER_MENU_ITEM')
                    : t('ORGANIZATION_DETAILS_PAGE.MAKE_USER_DEVELOPER_MENU_ITEM'),
            onClick: (): void => renderChangeUserRoleModal(),
        },
        {
            testid: 'remove-user',
            text: t('ORGANIZATION_DETAILS_PAGE.REMOVE_USER_MENU_ITEM'),
            onClick: (): void => renderRemoveUserModal(),
        },
    ];

    return (
        <Box data-testid="users-list">
            <InfoListItem
                sx={styles.bar}
                title={t('ORGANIZATION_DETAILS_PAGE.USERS_ASSIGNED_TO_ORG_TITLE')}
                data-testid="list-header"
                avatar={false}
                hidePadding={true}
                divider={'full'}
                wrapTitle={true}
                rightComponent={
                    <>
                        {isUserOwner && (
                            <Box sx={styles.rightComponent}>
                                <Button
                                    data-testid={'add-user-button'}
                                    sx={styles.button}
                                    variant="outlined"
                                    color="primary"
                                    onClick={(): void => {
                                        setShowAddUserModal(true);
                                    }}
                                >
                                    {t('ORGANIZATION_DETAILS_PAGE.ADD_USER_BUTTON')}
                                </Button>
                            </Box>
                        )}
                        <Typography data-testid="user-count" sx={styles.userCount} variant="body1">
                            {Object.keys(users).length}
                        </Typography>
                        <Group sx={styles.groupIcon} />
                    </>
                }
            />
            {sorted.map((user, index) => (
                <UsersListItem
                    key={index}
                    user={user}
                    itemLabel={user.role}
                    userMenuItems={getMenuItems(user.role)}
                    isUserOwner={isUserOwner}
                    showUserMenu={selectedUser === user}
                    setShowUserMenu={setSelectedUser}
                />
            ))}
            {showAddUserModal && (
                <AddUserModal
                    handleAddUserToOrg={handleAddUserToOrg}
                    setShowAddUserModal={setShowAddUserModal}
                    loading={loading}
                    error={errorAddingUser}
                />
            )}
        </Box>
    );
};

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { store } from '../app/store';
import { forceLogoutUser, setTokens, UserSelectors } from '../features/userSlice';
import { UserService } from './user/user.service';

const getTimeout = (args?: AxiosRequestConfig): number =>
    args?.timeout && args.timeout > 0 ? 1000 * args.timeout : 1000 * 90;

export const buildEnergyManagerClientWithoutAuth = (args?: AxiosRequestConfig): AxiosInstance =>
    axios.create({
        ...args,
        headers: args?.headers,
        timeout: getTimeout(args),
    });

const refreshTokens = async (failedRequest: any): Promise<void> => {
    const credentials = UserSelectors.credentials(store.getState());

    if (credentials) {
        try {
            const { accessToken: originalAccessToken, refreshToken: originalRefreshToken } = credentials;

            const { accessToken, refreshToken } = await UserService.refreshAccessToken(
                originalAccessToken,
                originalRefreshToken
            );

            store.dispatch(setTokens({ accessToken, refreshToken }));
            failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;
        } catch (e) {
            store.dispatch(forceLogoutUser());
        }
    }
};

export const buildEnergyManagerClient = (args?: AxiosRequestConfig): AxiosInstance => {
    const credentials = UserSelectors.credentials(store.getState());
    const accessToken = credentials ? credentials.accessToken : undefined;

    const headers = {
        ...(args?.headers ?? {}),
        ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
    };

    const axiosInstance = axios.create({
        ...args,
        headers,
        timeout: getTimeout(args),
    });

    createAuthRefreshInterceptor(axiosInstance, refreshTokens);

    return axiosInstance;
};

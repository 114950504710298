import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ErrorState } from '../../components/ErrorState';
import { PageWrapper } from '../../components/PageWrapper';
import { Spinner } from '../../components/Spinner';
import { getOrganization, OrganizationSelectors } from '../../features/organizationSlice';
import { OrganizationDetails } from './OrganizationDetails';

export const OrganizationDetailsPage: React.FC = () => {
    const params = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const id = params.id ?? '';

    const organization = useAppSelector((state) => OrganizationSelectors.organizationById(state, id));

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        const fetchOrganization = async (): Promise<void> => {
            try {
                await dispatch(getOrganization(id));
            } catch (err) {
                setShowError(true);
            }
        };

        void fetchOrganization();
    }, [dispatch, id]);

    return (
        <PageWrapper title={t('ORGANIZATION_DETAILS_PAGE.TITLE')}>
            {showError ? (
                <ErrorState />
            ) : organization ? (
                <OrganizationDetails organization={organization} />
            ) : (
                <Spinner />
            )}
        </PageWrapper>
    );
};

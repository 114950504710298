import {
    AuthContextProvider,
    ChangePasswordDialog,
    ForgotPasswordScreen,
    ReactRouterAuthGuard,
    ReactRouterGuestGuard,
    RegistrationContextProvider,
    RouteConfig,
} from '@brightlayer-ui/react-auth-workflow';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, NavigateFunction, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { ProjectAuthUIActions } from '../auth/AuthUIActions';
import { ProjectRegistrationUIActions } from '../auth/RegistrationUIActions';
import { AlertModal } from '../components/AlertModal';
import { useApp } from '../contexts/appContextProvider';
import { DrawerContext } from '../contexts/drawerContextProvider';
import { forceLogoutUser, UserSelectors } from '../features/userSlice';
import { LoginWorkflow } from '../pages/LoginWorkflow';
import { RegistrationWorkflow } from '../pages/Registration/RegistrationWorkflow';
import i18nAppInstance from '../translations/i18n';
import { AuthorizedDrawerPages, AuthorizedPages, DrawerDocumentationPages, DrawerPages } from './routes';

const routes: RouteConfig = {
    LOGIN: '/login',
    REGISTER_SELF: '/register/create-account',
};

export const Wrapper: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(true);
    const app = useApp();
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    return (
        <DrawerContext.Provider
            value={{
                drawerOpen,
                setDrawerOpen,
            }}
        >
            <Outlet />
            <AlertModal />
            <ChangePasswordDialog
                dialogDescription={t('CHANGE_PASSWORD.DESCRIPTION')}
                open={app.showChangePasswordDialog}
                onFinish={(): void => {
                    app.setShowChangePasswordDialog(false);
                    dispatch(forceLogoutUser());
                }}
                onPrevious={(): void => {
                    app.setShowChangePasswordDialog(false);
                }}
                /**
                 * LC - 10/02/2024
                 * An error modal appears when failing to change passwords.
                 * However, after closing the modal, the ChangePasswordDialog still appears.
                 * An issue for this bug has been submitted (https://github.com/etn-ccis/blui-react-workflows/issues/656).
                 * Once addressed, uncomment out the code below.
                 */
                // errorDisplayConfig={{
                //     onClose: (): void => {
                //         app.setShowChangePasswordDialog(false);
                //     },
                // }}
            />
        </DrawerContext.Provider>
    );
};

export const AppRouter: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const credentials = useAppSelector(UserSelectors.credentials);
    const rememberMe = useAppSelector(UserSelectors.rememberMe);

    const handleNavigation = (destination: string | -1, nav: NavigateFunction): void => {
        if (destination === -1) {
            nav(-1);
        } else {
            nav(destination);
        }
    };

    return (
        <Routes>
            <Route
                element={
                    <AuthContextProvider
                        actions={ProjectAuthUIActions(dispatch)}
                        language="en"
                        navigate={(destination): void => handleNavigation(destination, navigate)}
                        routeConfig={routes}
                        i18n={i18nAppInstance}
                        rememberMeDetails={{ email: rememberMe ? rememberMe.email : '', rememberMe: !!rememberMe }}
                    >
                        <Outlet />
                    </AuthContextProvider>
                }
            >
                <Route element={<Wrapper />}>
                    {DrawerPages.map((page) => {
                        const RouteElement = page.component;
                        return <Route key={`route_${page.route}`} path={page.route} element={<RouteElement />} />;
                    })}
                    {{ ...DrawerDocumentationPages }}
                </Route>
                <Route
                    path={'/login'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={!!credentials} fallBackUrl="/">
                            <LoginWorkflow />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    path={'/forgot-password'}
                    element={
                        <ReactRouterGuestGuard isAuthenticated={!!credentials} fallBackUrl={'/login'}>
                            <ForgotPasswordScreen />
                        </ReactRouterGuestGuard>
                    }
                />
                <Route
                    element={
                        <ReactRouterAuthGuard isAuthenticated={!!credentials} fallBackUrl={'/login'}>
                            <Wrapper />
                        </ReactRouterAuthGuard>
                    }
                >
                    {AuthorizedDrawerPages.map((page) => {
                        const RouteElement = page.component;
                        return <Route key={`route_${page.route}`} path={page.route} element={<RouteElement />} />;
                    })}
                    <>{AuthorizedPages}</>
                </Route>
                <Route path={'*'} element={<Navigate to={'/'} />} />
            </Route>
            <Route
                element={
                    <RegistrationContextProvider
                        language="en"
                        routeConfig={routes}
                        i18n={i18nAppInstance}
                        navigate={(destination): void => handleNavigation(destination, navigate)}
                        actions={ProjectRegistrationUIActions()}
                    >
                        <Outlet />
                    </RegistrationContextProvider>
                }
            >
                <Route path={'/register/create-account'} element={<RegistrationWorkflow />} />
            </Route>
        </Routes>
    );
};

import { combineReducers, configureStore, Reducer, UnknownAction } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import organizationReducer, { OrganizationState } from '../features/organizationSlice';
import userReducer, { UserState } from '../features/userSlice';

const userPersistConfig = {
    key: 'user',
    storage,
    whitelist: ['rememberMe', 'credentials'],
};

const getRootReducer = (): Reducer<RootState, UnknownAction, Partial<RootState>> =>
    combineReducers({
        user: persistReducer(userPersistConfig, userReducer),
        organization: organizationReducer,
    });

const rootPersistConfig = {
    key: 'root',
    storage,
    rootReducer: getRootReducer(),
    whitelist: [userPersistConfig.key],
};

export const store = configureStore({
    reducer: persistReducer(rootPersistConfig, getRootReducer()),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);

export type RootState = {
    user: UserState & PersistPartial;
    organization: OrganizationState;
};
export type AppDispatch = typeof store.dispatch;

export const setupStore = (preloadedState?: Partial<RootState>): ReturnType<typeof configureStore> =>
    configureStore({
        reducer: getRootReducer(),
        preloadedState,
    });

import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { GenericModal } from '../../components/GenericModal';
import { UserRole } from '../../services/user/types';

export const styles = {
    textField: {
        margin: '1rem 0',
        paddingRight: '2rem',
        width: '100%',
    },
    alertText: {
        color: 'red',
    },
};

type AddUserModalProps = {
    handleAddUserToOrg: (userEmail: string, role: UserRole) => Promise<void>;
    setShowAddUserModal: (show: boolean) => void;
    loading: boolean;
    error: boolean;
};

export const AddUserModal: React.FC<AddUserModalProps> = (props) => {
    const { handleAddUserToOrg, setShowAddUserModal, loading: loadingState, error } = props;
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [selectedRole, setSelectedRole] = useState<UserRole | ''>('');

    const userEmailSchemaValidations = (inputtedEmail: string): boolean =>
        z.string().email().safeParse(inputtedEmail).success;

    return (
        <GenericModal
            modalTestId="add-user-modal"
            title={
                error
                    ? t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_ADD_USER_TO_ORG_TITLE')
                    : t('ORGANIZATION_DETAILS_PAGE.ADD_USER_MODAL_TITLE')
            }
            cancelText={t('COMMON.CANCEL')}
            confirmText={t('COMMON.SUBMIT')}
            confirmOnClick={(): void => {
                if (selectedRole) {
                    void handleAddUserToOrg(email, selectedRole);
                }
            }}
            setShowModal={(): void => setShowAddUserModal(false)}
            disable={error || emailError || !email || !selectedRole}
            loading={loadingState}
        >
            {error ? (
                <Typography sx={styles.alertText}>
                    {t('ORGANIZATION_DETAILS_PAGE.FAILED_TO_ADD_USER_TO_ORG_BODY')}
                </Typography>
            ) : (
                <>
                    <TextField
                        data-testid="add-user-email-input"
                        label={t('ORGANIZATION_DETAILS_PAGE.ADD_USER_MODAL_EMAIL_LABEL')}
                        sx={styles.textField}
                        value={email}
                        onChange={(e): void => {
                            setEmailError(false);
                            setEmail(e.target.value);
                        }}
                        error={emailError}
                        helperText={emailError ? t('VALIDATION_ERRORS.ORGANIZATION_DETAILS.EMAIL_ERROR') : undefined}
                        onBlur={(): void => {
                            if (email !== '') {
                                setEmailError(!userEmailSchemaValidations(email));
                            }
                        }}
                    />
                    <FormControl fullWidth sx={styles.textField}>
                        <InputLabel>{t('ORGANIZATION_DETAILS_PAGE.ADD_USER_MODAL_ROLE_LABEL')}</InputLabel>
                        <Select
                            data-testid="add-user-role-select"
                            value={selectedRole}
                            label={t('ORGANIZATION_DETAILS_PAGE.ADD_USER_MODAL_ROLE_LABEL')}
                            onChange={(e): void => {
                                const role = e.target.value === 'owner' ? UserRole.OWNER : UserRole.DEVELOPER;
                                setSelectedRole(role);
                            }}
                        >
                            <MenuItem value={UserRole.OWNER}>{t('COMMON.OWNER')}</MenuItem>
                            <MenuItem value={UserRole.DEVELOPER}>{t('COMMON.DEVELOPER')}</MenuItem>
                        </Select>
                    </FormControl>
                </>
            )}
        </GenericModal>
    );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { OrganizationService } from '../services/organization/organization.service';
import {
    CreateOrganization,
    CreatedOrganization,
    GetUsersInOrganization,
    Organization,
    OrganizationSecretWithValue,
    UpdateOrganization,
} from '../services/organization/types';
import { UserRole } from '../services/user/types';

export type UserInOrg = Record<string, Omit<GetUsersInOrganization, 'id'>>;

export type OrganizationState = {
    data: Record<string, Organization & { users: UserInOrg }>;
};

const initialState: OrganizationState = {
    data: {},
};

export const getOrganization = createAsyncThunk<Organization, string>(
    'organization',
    async (organizationId) => await OrganizationService.getOrganization(organizationId)
);

export const createOrganization = createAsyncThunk<CreatedOrganization, CreateOrganization>(
    'organizations/create',
    async (organization) => await OrganizationService.createOrganization(organization)
);

export const updateOrganization = createAsyncThunk<Organization, UpdateOrganization>(
    'organizations/edit',
    async (organization) => await OrganizationService.updateOrganization(organization)
);

export type GetUsersInOrganizationResponse = {
    users: GetUsersInOrganization[];
    orgId: string;
};

export const getUsersInOrganization = createAsyncThunk<GetUsersInOrganizationResponse, string>(
    'organizations/users',
    async (organizationId) => {
        const result = await OrganizationService.getUsersInOrganization(organizationId);

        return {
            users: result,
            orgId: organizationId,
        };
    }
);

type RotateOrgServiceAccountSecretArgs = {
    organizationId: string;
    secretName: string;
};
type RotateOrgServiceAccountSecretResponse = {
    organizationClientSecret: OrganizationSecretWithValue;
    organizationId: string;
};
export const rotateOrgServiceAccountSecret = createAsyncThunk<
    RotateOrgServiceAccountSecretResponse,
    RotateOrgServiceAccountSecretArgs
>('/organizations/rotateSecret', async (args) => {
    const result = await OrganizationService.rotateOrgServiceAccountSecret(args.organizationId, args.secretName);

    return {
        organizationClientSecret: result,
        organizationId: args.organizationId,
    };
});

type AssignOrganizationRoleToUserArgs = {
    organizationId: string;
    userId?: string;
    email?: string;
    role: UserRole;
};

type AssignOrganizationRoleToUserResponse = {
    organizationId: string;
    userId?: string;
    email?: string;
    role: UserRole;
};
export const assignOrganizationRoleToUser = createAsyncThunk<
    AssignOrganizationRoleToUserResponse,
    AssignOrganizationRoleToUserArgs
>('/organizations/users/assignRole', async (args) => {
    await OrganizationService.assignOrganizationRoleToUser(args);

    return {
        organizationId: args.organizationId,
        userId: args.userId ?? undefined,
        email: args.email ?? undefined,
        role: args.role,
    };
});

type RemoveOrganizationUserArgs = {
    organizationId: string;
    userId: string;
};

type RemoveOrganizationUserResponse = {
    organizationId: string;
    userId: string;
};

export const removeOrganizationUser = createAsyncThunk<RemoveOrganizationUserResponse, RemoveOrganizationUserArgs>(
    '/organizations/users/remove',
    async (args) => {
        await OrganizationService.removeOrganizationUser(args);

        return {
            organizationId: args.organizationId,
            userId: args.userId,
        };
    }
);

export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOrganization.fulfilled, (state, action) => {
            state.data[action.payload.id] = { ...action.payload, users: {} };
        });
        builder.addCase(createOrganization.fulfilled, (state, action) => {
            const organization = {
                ...action.payload.organization,
                serviceAccount: {
                    clientId: action.payload.serviceAccount.clientId,
                    clientSecrets: action.payload.serviceAccount.clientSecrets.map((secret) => ({
                        name: secret.name,
                        expiration: secret.expiration,
                    })),
                },
                users: {},
            };
            state.data[action.payload.organization.id] = organization;
        });
        builder.addCase(updateOrganization.fulfilled, (state, action) => {
            const organization = {
                ...action.payload,
                serviceAccount: {
                    clientId: action.payload.serviceAccount.clientId,
                    clientSecrets: action.payload.serviceAccount.clientSecrets.map((secret) => ({
                        name: secret.name,
                        expiration: secret.expiration,
                    })),
                },
                users: {},
            };
            state.data[action.payload.id] = organization;
        });

        builder.addCase(getUsersInOrganization.fulfilled, (state, action) => {
            state.data[action.payload.orgId].users = action.payload.users.reduce((acc: UserInOrg, user) => {
                acc[user.id] = {
                    ...user,
                    role: user.role,
                };
                return acc;
            }, {});
        });
        builder.addCase(rotateOrgServiceAccountSecret.fulfilled, (state, action) => {
            const organizationSecrets = state.data[action.payload.organizationId].serviceAccount.clientSecrets;

            const updatedSecrets = organizationSecrets.map((secret) => {
                if (secret.name === action.payload.organizationClientSecret.name) {
                    return {
                        name: action.payload.organizationClientSecret.name,
                        expiration: action.payload.organizationClientSecret.expiration,
                    };
                }
                return secret;
            });

            state.data[action.payload.organizationId].serviceAccount.clientSecrets = updatedSecrets;
        });
        builder.addCase(assignOrganizationRoleToUser.fulfilled, (state, action) => {
            const { organizationId, userId, email, role } = action.payload;

            if (userId) {
                state.data[organizationId].users[userId] = {
                    ...state.data[organizationId].users[userId],
                    role,
                };
            } else if (email) {
                const userByEmail = Object.values(state.data[organizationId].users).find(
                    (user) => user.email === email
                );

                if (userByEmail) {
                    userByEmail.role = role;
                } else {
                    state.data[organizationId].users = {};
                }
            }
        });
        builder.addCase(removeOrganizationUser.fulfilled, (state, action) => {
            const { organizationId, userId } = action.payload;
            delete state.data[organizationId].users[userId];
        });
    },
});

export const OrganizationSelectors = {
    organizationById: (state: RootState, id: string): Organization | undefined => state.organization.data[id],
    getUserRoleInOrganization: (state: RootState, organizationId: string, userId: string): UserRole | undefined => {
        const organization = state.organization.data[organizationId];

        if (!organization || !organization.users || !organization.users[userId]) {
            return undefined;
        }
        return organization.users[userId].role;
    },
    getUsersInOrganization: (state: RootState, organizationId: string): UserInOrg =>
        state.organization.data[organizationId]?.users,
};

export default organizationSlice.reducer;
